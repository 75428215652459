import React, { useCallback } from 'react';
import { Button } from '@mui/material';

import { getChain } from '../../../chainServices';
import { useTranslation } from '../../../i18n';
import { useAuthProvider } from '../../hooks/authProvider';
import { addNetworkTranslation } from './translation';
import { useAddNetworkButtonStyles } from './useAddNetworkButtonStyles';

export function AddNetworkButton() {
  const { classes } = useAddNetworkButtonStyles();

  const { keys, t } = useTranslation(addNetworkTranslation);

  const currentChain = getChain();

  const {
    data: providerConnectData,
    isLoading: isProviderConnectLoading,
    switchNetwork,
  } = useAuthProvider();

  const handleSwitch = useCallback(() => {
    void switchNetwork(currentChain);
  }, [currentChain, switchNetwork]);

  if (
    isProviderConnectLoading ||
    providerConnectData?.chainId === currentChain.chainId
  ) {
    return null;
  }

  return (
    <Button
      disabled={!providerConnectData?.chainId}
      className={classes.button}
      onClick={handleSwitch}
      size="small"
    >
      {t(keys.addNetwork)}
      {providerConnectData?.walletMeta.icon && (
        <img
          src={providerConnectData.walletMeta.icon}
          className={classes.walletIcon}
          alt=""
        />
      )}
    </Button>
  );
}
