import { Web3KeyReadProvider } from '@ankr.com/provider';

import { CONTRACT_ADDRESS } from '../const';
import TokenFactoryAbi from '../contracts/TokenFactory.json';

export function getTokenFactoryContract(provider: Web3KeyReadProvider) {
  return provider.createContract(
    TokenFactoryAbi,
    CONTRACT_ADDRESS.tokenFactory,
  );
}
