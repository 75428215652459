import { makeStyles } from 'tss-react/mui';

export const useFooterStyles = makeStyles()(theme => ({
  container: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: theme.spacing(3),
    padding: theme.spacing(8, 10),
    '&&': {
      maxWidth: 'none',
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  item: {
    flexGrow: 1,
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  copyright: {
    color: theme.palette.text.secondary,
  },
  contractWrap: {
    textAlign: 'center',
  },
  contractBtn: {
    color: theme.palette.text.secondary,
    display: 'inline-flex',
    gap: theme.spacing(1),
    alignItems: 'flex-end',
    '&:hover': {
      color: theme.palette.grey[600],
    },
  },
  socialWrap: {
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },
  socialBtn: {
    '&&': {
      color: theme.palette.grey[500],
      borderColor: 'transparent',
      backgroundColor: 'transparent',
      '&:hover': {
        color: theme.palette.grey[600],
      },
    },
  },
}));
