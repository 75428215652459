import React, { JSX, useCallback } from 'react';
import { AppModal } from '@ankr.com/chain-tools-ui';
import { Box, Button, Typography } from '@mui/material';

import { DialogId } from '../../../../../common/actions/openDialog';
import { useDialog } from '../../../../../common/hooks/useDialog';
import { useTranslation } from '../../../../../i18n';
import { useGenerateTokenFormModalStyles } from '../useGenerateTokenFormModalStyles';
import FailedIcon from './assets/failed.svg?react';
import { generateTokenFormFailedModalTranslation } from './translation';

interface IGenerateTokenFormFailedModalProps {
  onClick: () => void;
}

export function GenerateTokenFormFailedModal({
  onClick,
}: IGenerateTokenFormFailedModalProps): JSX.Element {
  const { classes } = useGenerateTokenFormModalStyles();

  const { keys, t } = useTranslation(generateTokenFormFailedModalTranslation);

  const { open, handleClose } = useDialog(DialogId.TokenGeneratedFailed);

  const handleClick = useCallback(() => {
    onClick();
    handleClose();
  }, [handleClose, onClick]);

  return (
    <AppModal
      open={open}
      onClose={handleClose}
      maxWidth="xs"
      classes={{ paper: classes.modalContainer }}
    >
      <Box className={classes.modalContent}>
        <FailedIcon className={classes.modalIcon} />

        <Typography variant="subtitle1" className={classes.modalTitle}>
          {t(keys.title)}
        </Typography>

        <Box className={classes.modalButtons}>
          <Button
            onClick={handleClick}
            size="large"
            fullWidth
            className={classes.modalBtn}
          >
            {t(keys.button)}
          </Button>
        </Box>
      </Box>
    </AppModal>
  );
}
