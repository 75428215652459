import { alpha } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

export const useConnectWalletButtonStyles = makeStyles()(theme => ({
  btn: {
    fontWeight: 500,
  },
  btnConnect: {
    '&&': {
      textTransform: 'uppercase',
    },
  },
  btnConnected: {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.default,
    fontWeight: 400,
    gap: theme.spacing(2),
    boxShadow: `0 0 0 2px ${alpha(theme.palette.grey[900], 0.4)}`,
    '&&': {
      textTransform: 'none',
    },
    '&:hover': {
      color: theme.palette.primary.dark,
    },
  },
  icon: {
    width: 24,
    height: 24,
  },
  iconSmall: {
    width: 16,
    height: 16,
  },
}));
