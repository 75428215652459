import React, { JSX, useCallback } from 'react';
import { Web3Address } from '@ankr.com/chain-tools-types';
import { AppModal, CopyButton } from '@ankr.com/chain-tools-ui';
import { truncateWalletAddress } from '@ankr.com/chain-tools-utils';
import { EWalletId } from '@ankr.com/provider';
import { Box, Button } from '@mui/material';

import { DialogId } from '../../../common/actions/openDialog';
import { useDialog } from '../../../common/hooks/useDialog';
import { useTranslation } from '../../../i18n';
import { useAuthProvider } from '../../hooks/authProvider';
import { accountModalTranslation } from './translation';
import { useAccountModalStyles } from './useAccountModalStyles';

interface IAccountModalProps {
  account: Web3Address;
  walletIcon: JSX.Element | null;
}

export function AccountModal({
  account,
  walletIcon,
}: IAccountModalProps): JSX.Element {
  const { classes } = useAccountModalStyles();

  const { keys, t } = useTranslation(accountModalTranslation);

  const { open, handleClose } = useDialog(DialogId.AccountModal);

  const { disconnect: disconnectWallet } = useAuthProvider();

  const handleDisconnect = useCallback(() => {
    void disconnectWallet(EWalletId.injected);
    handleClose();
  }, [disconnectWallet, handleClose]);

  return (
    <AppModal
      open={open}
      onClose={handleClose}
      maxWidth="xs"
      title={t(keys.title)}
    >
      <Box className={classes.walletWrap}>
        <Box className={classes.wallet}>
          {walletIcon}
          {truncateWalletAddress(account)}
        </Box>
        <CopyButton
          text={account}
          className={classes.copyBtn}
          size="small"
          color="inherit"
        />
      </Box>
      <Button
        className={classes.disconnectBtn}
        size="large"
        onClick={handleDisconnect}
      >
        {t(keys.disconnect)}
      </Button>
    </AppModal>
  );
}
