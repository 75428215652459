import { makeStyles } from 'tss-react/mui';

export const useGenerateTokenFormModalStyles = makeStyles()(theme => ({
  modalContainer: {
    padding: 0,
    maxWidth: 600,
  },
  modalContent: {
    padding: theme.spacing(1, 5, 5),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    alignContent: 'center',
    textAlign: 'center',
  },
  modalIcon: {
    width: 60,
    height: 60,
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(5),
  },
  modalTitle: {
    fontSize: 24,
    fontWeight: 400,
  },
  modalSubtitleWrap: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  modalSubtitle: {
    fontWeight: 500,
  },
  wordBreak: {
    wordBreak: 'break-all',
  },
  modalButtons: {
    marginTop: theme.spacing(10),
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
  },
  modalBtn: {},
}));
