import React, { useCallback } from 'react';
import { truncateWalletAddress } from '@ankr.com/chain-tools-utils';
import { EWalletId } from '@ankr.com/provider';
import { Button } from '@mui/material';
import { ButtonPropsSizeOverrides } from '@mui/material/Button/Button';
import { OverridableStringUnion } from '@mui/types';

import { DialogId } from '../../../common/actions/openDialog';
import { useDialog } from '../../../common/hooks/useDialog';
import { useTranslation } from '../../../i18n';
import { useAuthProvider } from '../../hooks/authProvider';
import { AccountModal } from '../AccountModal';
import { connectWalletButtonTranslation } from './translation';
import { useConnectWalletButtonStyles } from './useConnectWalletButtonStyles';

interface IConnectWalletButtonProps {
  className?: string;
  onlyConnectButton?: boolean;
  size?: OverridableStringUnion<
    'small' | 'medium' | 'large',
    ButtonPropsSizeOverrides
  >;
}

export function ConnectWalletButton({
  className,
  onlyConnectButton = false,
  size = 'medium',
}: IConnectWalletButtonProps) {
  const { keys, t } = useTranslation(connectWalletButtonTranslation);

  const { handleOpen: handleAccountModalOpen } = useDialog(
    DialogId.AccountModal,
  );

  const { classes, cx } = useConnectWalletButtonStyles();

  const { connect: connectWallet, isLoading, data } = useAuthProvider();

  const handleClick = useCallback(async () => {
    await connectWallet(EWalletId.injected);
  }, [connectWallet]);

  if (data?.isActive && !onlyConnectButton) {
    return (
      <>
        <Button
          className={cx(classes.btn, classes.btnConnected, className)}
          onClick={handleAccountModalOpen}
          startIcon={
            data.walletMeta.icon ? (
              <img
                src={data.walletMeta.icon}
                className={cx(
                  classes.icon,
                  size === 'small' && classes.iconSmall,
                )}
                alt=""
              />
            ) : null
          }
          disabled={isLoading}
          size={size}
        >
          {truncateWalletAddress(data.currentAccount)}
        </Button>
        <AccountModal
          account={data.currentAccount}
          walletIcon={
            data.walletMeta.icon ? (
              <img src={data.walletMeta.icon} className={classes.icon} alt="" />
            ) : null
          }
        />
      </>
    );
  }

  return (
    <Button
      className={cx(classes.btn, classes.btnConnect, className)}
      onClick={handleClick}
      disabled={isLoading}
      size={size}
    >
      {t(keys.connect)}
    </Button>
  );
}
