import { Locale } from '../../../../../i18n';

export const generateTokenFormSuccessModalTranslation = {
  [Locale.en]: {
    title: 'Your token has been successfully generated',
    transaction: 'Transaction:',
    contractAddress: 'New contract address:',
    copy: 'Copy Contract Address',
    copied: 'Copied!',
    button: 'Close',
  },
};
