import { JSX, useMemo } from 'react';
import { QueryLoadingAbsolute } from '@ankr.com/chain-tools-ui';

import { getChain } from '../../../chainServices';
import { useAuthProvider } from '../../hooks/authProvider';
import { ConnectWallet } from '../ConnectWallet';
import { UnsupportedNetwork } from '../UnsupportedNetwork';

interface IGuardRouteProps {
  children: JSX.Element;
  checkChain?: boolean;
}

export function GuardRoute({
  children,
  checkChain = true,
}: IGuardRouteProps): JSX.Element {
  const {
    data: providerConnectData,
    isLoading: isProviderConnectLoading,
    switchNetwork,
  } = useAuthProvider();

  return useMemo(() => {
    if (isProviderConnectLoading) {
      return <QueryLoadingAbsolute />;
    }

    if (!providerConnectData) {
      return <ConnectWallet />;
    }

    const currentChain = getChain();

    if (checkChain && providerConnectData.chainId !== currentChain.chainId) {
      return (
        <UnsupportedNetwork
          currentChainId={providerConnectData.chainId}
          requiredChainId={currentChain.chainId}
          onSwitch={() => switchNetwork(currentChain)}
        />
      );
    }

    return children;
  }, [
    checkChain,
    children,
    isProviderConnectLoading,
    providerConnectData,
    switchNetwork,
  ]);
}
