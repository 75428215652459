import React, { JSX } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { QueryLoadingAbsolute } from '@ankr.com/chain-tools-ui';
import { PersistGate } from 'redux-persist/integration/react';

import { AppBase } from './modules/layout/components/AppBase';
import { AppRoutes } from './Routes';
import { persistor, store } from './store';

function App(): JSX.Element {
  return (
    <Provider store={store}>
      <PersistGate loading={<QueryLoadingAbsolute />} persistor={persistor}>
        <BrowserRouter basename={import.meta.env.VITE_HOMEPAGE}>
          <AppBase>
            <AppRoutes />
          </AppBase>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
}

export default App;
