import { EthereumWeb3KeyProvider } from '@ankr.com/provider';

import { getChain } from '../chainServices';

export class WriteProvider extends EthereumWeb3KeyProvider {
  private static instance: WriteProvider;

  static getInstance(): WriteProvider {
    const currentChain = getChain();

    if (!WriteProvider.instance) {
      WriteProvider.instance = new WriteProvider({
        rpcConfig: {
          [currentChain.chainId]: currentChain.rpcUrls[0],
        },
      });
    }

    return WriteProvider.instance;
  }
}
