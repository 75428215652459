import { useCallback } from 'react';
import { IChain } from '@ankr.com/chain-tools-types';
import { t } from '@ankr.com/chain-tools-utils';
import { skipToken } from '@reduxjs/toolkit/query';

import { useConnectMutation } from '../actions/connect';
import { useDisconnectMutation } from '../actions/disconnect';
import { useGetAuthQuery } from '../actions/getAuth';
import { useSwitchNetworkMutation } from '../actions/switchNetwork';
import { CONNECTION_CACHE_KEY, DISCONNECTION_CACHE_KEY } from '../const';

export function useAuthProvider() {
  const [
    connect,
    { data: connectProviderData, isLoading: isConnectProviderLoading },
  ] = useConnectMutation({
    fixedCacheKey: CONNECTION_CACHE_KEY,
  });

  const [disconnect] = useDisconnectMutation({
    fixedCacheKey: DISCONNECTION_CACHE_KEY,
  });

  const { currentData: connectedData, isLoading: isGetConnectedLoading } =
    useGetAuthQuery(connectProviderData?.walletId || skipToken);

  const [switchNetwork, { isLoading: isSwitchNetworkLoading }] =
    useSwitchNetworkMutation();

  const handleSwitchNetwork = useCallback(
    (chain: IChain) => {
      if (!connectedData) {
        throw new Error(t('auth-provider.error.wallet-is-not-connected'));
      }

      return switchNetwork({
        chain,
        walletId: connectedData?.walletId,
      });
    },
    [connectedData, switchNetwork],
  );

  return {
    connect,
    switchNetwork: handleSwitchNetwork,
    disconnect,
    data: connectedData,
    isLoading:
      isConnectProviderLoading ||
      isGetConnectedLoading ||
      isSwitchNetworkLoading,
  };
}
