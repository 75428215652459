import React, { JSX } from 'react';
import { CardDialog } from '@ankr.com/chain-tools-ui';
import { EEthereumNetworkId } from '@ankr.com/provider';
import { Button, Container, Typography } from '@mui/material';

import { useTranslation } from '../../../i18n';
import { unsupportedNetworkTranslation } from './translation';
import { useUnsupportedNetworkStyles } from './useUnsupportedNetworkStyles';

interface IUnsupportedNetworkProps {
  currentChainId: EEthereumNetworkId;
  requiredChainId: EEthereumNetworkId;
  onSwitch: () => void;
}

export function UnsupportedNetwork({
  currentChainId,
  requiredChainId,
  onSwitch,
}: IUnsupportedNetworkProps): JSX.Element {
  const { classes, cx } = useUnsupportedNetworkStyles();

  const { keys, t } = useTranslation(unsupportedNetworkTranslation);

  return (
    <Container className={classes.container}>
      <CardDialog hideCloseButton rootClass={classes.content}>
        <Typography variant="h1" className={classes.title}>
          {t(keys.title)}
        </Typography>
        <Typography
          variant="body2"
          className={cx(classes.hint, classes.hintFirst)}
        >
          {t(keys.hint, {
            value: currentChainId,
          })}
        </Typography>
        <Typography variant="body2" className={classes.hint}>
          {t(keys.switchTo, { value: requiredChainId })}
        </Typography>
        <Button className={classes.btn} onClick={onSwitch}>
          {t(keys.button)}
        </Button>
      </CardDialog>
    </Container>
  );
}
