import { Locale } from '../../../i18n';

export const unsupportedNetworkTranslation = {
  [Locale.en]: {
    title: 'Wrong Network',
    hint: 'The current page is not available on network with ID {value}',
    switchTo: 'Please switch to network with ID {value}',
    button: 'Switch Network',
  },
};
