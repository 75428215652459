import { IChain } from '@ankr.com/chain-tools-types';
import { queryFnNotifyWrapper } from '@ankr.com/chain-tools-ui';
import { EWalletId } from '@ankr.com/provider';

import { cacheTags } from '../../api';
import { authApi } from '../../api/authApi';
import { getWriteProvider } from '../../web3Provider';
import { changeNetwork } from '../utils/web3Utils';

interface ISwitchNetworkPayload {
  chain: IChain;
  walletId: EWalletId;
}

export const { useSwitchNetworkMutation } = authApi.injectEndpoints({
  endpoints: build => ({
    switchNetwork: build.mutation<boolean, ISwitchNetworkPayload>({
      queryFn: queryFnNotifyWrapper<ISwitchNetworkPayload, never, boolean>(
        async ({ chain, walletId }) => {
          const provider = await getWriteProvider(walletId);
          const web3 = provider.getWeb3();

          await changeNetwork(web3, chain);

          // TODO Replace to a more reliable check that provider is ready
          await new Promise(resolve => {
            setTimeout(resolve, 2000);
          });

          provider.currentChain = chain.chainId;

          return { data: true };
        },
      ),
      invalidatesTags: [cacheTags.providerConnection],
    }),
  }),
});
