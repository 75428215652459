import { queryFnNotifyWrapper } from '@ankr.com/chain-tools-ui';
import { EWalletId } from '@ankr.com/provider';

import { cacheTags } from '../../api';
import { authApi } from '../../api/authApi';
import { getWriteProvider } from '../../web3Provider';
import { storeWalletId } from '../utils/storeWalletId';

interface IConnect {
  walletId?: EWalletId;
}

export const {
  useConnectMutation,
  endpoints: { connect },
} = authApi.injectEndpoints({
  endpoints: build => ({
    connect: build.mutation<IConnect, EWalletId | undefined>({
      queryFn: queryFnNotifyWrapper<EWalletId | undefined, void, IConnect>(
        async (walletId = EWalletId.injected) => {
          await getWriteProvider(walletId);

          return { data: { walletId } };
        },
      ),
      async onQueryStarted(_arg, { queryFulfilled }) {
        const {
          data: { walletId },
        } = await queryFulfilled;

        storeWalletId(walletId);
      },
      invalidatesTags: [cacheTags.providerConnection],
    }),
  }),
});
