import { IChain } from '@ankr.com/chain-tools-types';

export const CONTRACT_ADDRESS = {
  tokenFactory: '0x0000000000000000000000000000000000007007',
};

interface IAvailableChains {
  [key: string]: IChain;
}

export const CHAINS: IAvailableChains = {
  incentivDev: {
    name: 'Incentiv Devnet',
    shortName: 'tIncentiv',
    chain: 'Incentiv',
    chainId: 16350,
    rpcUrls: ['https://rpc.ankr.com/incentiv_devnet'],
    faucetList: ['https://faucet.incentiv-dev.ankr.network'],
    nativeCurrency: { name: 'Testnet INC', symbol: 'INC', decimals: 18 },
    blockExplorerUrls: [
      {
        name: 'Incentiv Devnet Block Explorer',
        url: 'https://explorer.incentiv-dev.ankr.network',
        standard: '',
      },
    ],
    infoURL: 'https://incentiv.net',
    slip44: 1,
  },
};

export type CHAINS = (typeof CHAINS)[keyof typeof CHAINS];
