import React, { useMemo } from 'react';
import { Container } from '@mui/material';

import { IncentivFooter } from './components/IncentivFooter';
import { useFooterStyles } from './useFooterStyles';

export function Footer() {
  const { classes } = useFooterStyles();

  return useMemo(() => {
    switch (import.meta.env.VITE_PROJECT_NAME) {
      case 'Incentiv':
        return (
          <Container className={classes.container}>
            <IncentivFooter />
          </Container>
        );
      default:
        return null;
    }
  }, [classes.container]);
}
