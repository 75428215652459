import React, { JSX } from 'react';
import { CardDialog } from '@ankr.com/chain-tools-ui';
import { Container, Typography } from '@mui/material';

import { useTranslation } from '../../../i18n';
import { ConnectWalletButton } from '../ConnectWalletButton';
import { connectWalletTranslation } from './translation';
import { useConnectWalletStyles } from './useConnectWalletStyles';

export function ConnectWallet(): JSX.Element {
  const { classes } = useConnectWalletStyles();

  const { keys, t } = useTranslation(connectWalletTranslation);

  return (
    <Container className={classes.container}>
      <CardDialog hideCloseButton rootClass={classes.content}>
        <Typography variant="h1" className={classes.title}>
          {t(keys.title)}
        </Typography>

        <Typography variant="body2" className={classes.hint}>
          {t(keys.hint)}
        </Typography>

        <ConnectWalletButton className={classes.btn} size="large" />
      </CardDialog>
    </Container>
  );
}
