import { HEADER_HEIGHT } from '@ankr.com/chain-tools-themes';
import { makeStyles } from 'tss-react/mui';

export const useHeaderStyles = makeStyles()(theme => ({
  container: {
    height: HEADER_HEIGHT,
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    '&&': {
      maxWidth: 'none',
    },
  },

  logo: {
    height: 22,
    width: 'auto',
  },

  buttons: {
    display: 'flex',
    gap: theme.spacing(2),
  },
}));
