import { Web3Address } from '@ankr.com/chain-tools-types';
import { queryFnNotifyWrapper } from '@ankr.com/chain-tools-ui';
import { TransactionHash, utils } from 'web3';

import { mainApi } from '../../api';
import { CONTRACT_ADDRESS, getTokenFactoryContract } from '../../chainServices';
import { getWriteProvider } from '../../web3Provider';

export interface IFormPayload {
  name: string;
  symbol: string;
  supply: string;
  pausable: 'yes' | 'no';
}

export interface IPostTokenResponse {
  transactionHash?: TransactionHash;
  newContractAddr?: Web3Address;
}

export const { usePostTokenFormMutation } = mainApi.injectEndpoints({
  endpoints: build => ({
    postTokenForm: build.mutation<IPostTokenResponse, IFormPayload>({
      queryFn: queryFnNotifyWrapper<IFormPayload, never, IPostTokenResponse>(
        async payload => {
          const provider = await getWriteProvider();

          const owner = provider.currentAccount;

          const contract = getTokenFactoryContract(provider);

          const data = contract.methods
            .deployToken(
              owner,
              payload.name,
              payload.symbol,
              utils.toWei(payload.supply, 'ether'),
              payload.pausable === 'yes',
            )
            .encodeABI();

          const { receiptPromise, transactionHash } =
            await provider.sendTransactionAsync(
              provider.currentAccount,
              CONTRACT_ADDRESS.tokenFactory,
              {
                data,
                estimate: true,
              },
            );

          await receiptPromise;

          return {
            data: {
              transactionHash,
            },
          };
        },
      ),
    }),
  }),
});
