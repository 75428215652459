import { Locale } from '../i18n';

export const formTranslation = {
  [Locale.en]: {
    generateTokenForm: {
      title: 'Generate Your Token',
      name: 'Name',
      namePlaceholder: 'Enter Your Token Name',
      symbol: 'Symbol',
      symbolPlaceholder: 'Enter Your Token Symbol',
      supply: 'Supply',
      supplyPlaceholder: 'Enter Your Token Supply',
      pausable: 'Pausable',
      yes: 'Yes',
      no: 'No',
      button: 'Generate Token',
    },
  },
};
