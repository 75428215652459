import React, { JSX, useMemo } from 'react';
import { Web3Address } from '@ankr.com/chain-tools-types';
import { AppModal, CopyButton } from '@ankr.com/chain-tools-ui';
import { truncateWalletAddress } from '@ankr.com/chain-tools-utils';
import { Copy, ExternalLink } from '@ankr.com/ui';
import { Box, Button, Typography } from '@mui/material';
import { TransactionHash } from 'web3';

import { getChain } from '../../../../../chainServices';
import { DialogId } from '../../../../../common/actions/openDialog';
import { useDialog } from '../../../../../common/hooks/useDialog';
import { useTranslation } from '../../../../../i18n';
import { useGenerateTokenFormModalStyles } from '../useGenerateTokenFormModalStyles';
import SuccessIcon from './assets/success.svg?react';
import { generateTokenFormSuccessModalTranslation } from './translation';

interface IGenerateTokenFormSuccessModalProps {
  transactionHash?: TransactionHash;
  contractAddress?: Web3Address;
}

export function GenerateTokenFormSuccessModal({
  transactionHash,
  contractAddress,
}: IGenerateTokenFormSuccessModalProps): JSX.Element {
  const { classes } = useGenerateTokenFormModalStyles();

  const { keys, t } = useTranslation(generateTokenFormSuccessModalTranslation);

  const { open, handleClose } = useDialog(DialogId.TokenGeneratedSuccessfully);

  const currentChain = getChain();

  const txLinkToBlockExplorer = useMemo(() => {
    if (transactionHash) {
      const blockExplorerUrl: string = currentChain.blockExplorerUrls[0].url;
      return `${blockExplorerUrl}/tx/${transactionHash}`;
    }
  }, [currentChain.blockExplorerUrls, transactionHash]);

  return (
    <AppModal
      open={open}
      onClose={handleClose}
      maxWidth="xs"
      classes={{ paper: classes.modalContainer }}
    >
      <Box className={classes.modalContent}>
        <SuccessIcon className={classes.modalIcon} />

        <Typography variant="subtitle1" className={classes.modalTitle}>
          {t(keys.title)}
        </Typography>

        {!!transactionHash && !!txLinkToBlockExplorer && (
          <Box className={classes.modalSubtitleWrap}>
            <Typography variant="subtitle2" className={classes.modalSubtitle}>
              {t(keys.transaction)}
            </Typography>

            <Typography variant="subtitle2" className={classes.modalSubtitle}>
              <Button
                variant="text"
                size="small"
                className={classes.wordBreak}
                endIcon={<ExternalLink />}
                href={txLinkToBlockExplorer}
                rel="noreferrer"
                target="_blank"
              >
                {truncateWalletAddress(transactionHash, {
                  firstPartLength: 20,
                  lastPartLength: 20,
                })}
              </Button>
            </Typography>
          </Box>
        )}

        {!!contractAddress && (
          <Box className={classes.modalSubtitleWrap}>
            <Typography variant="subtitle2" className={classes.modalSubtitle}>
              {t(keys.contractAddress)}
            </Typography>

            <CopyButton
              text={contractAddress}
              tooltipPlacement="bottom"
              renderButton={isCopied => (
                <Button
                  variant="text"
                  size="small"
                  className={classes.wordBreak}
                  endIcon={<Copy />}
                  fullWidth
                  color={isCopied ? 'success' : 'primary'}
                >
                  {isCopied ? t(keys.copied) : contractAddress}
                </Button>
              )}
            />
          </Box>
        )}

        <Box className={classes.modalButtons}>
          <Button
            onClick={handleClose}
            size="large"
            fullWidth
            className={classes.modalBtn}
          >
            {t(keys.button)}
          </Button>
        </Box>
      </Box>
    </AppModal>
  );
}
