import { createApi, fakeBaseQuery } from '@reduxjs/toolkit/query/react';

import { cacheTags } from './cacheTags';

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: fakeBaseQuery<string>(),
  tagTypes: Object.values(cacheTags),
  endpoints: () => ({}),
});
