import { mainApi } from '../../api';

export const DialogId = {
  TokenGeneratedSuccessfully: 'TokenGeneratedSuccessfully',
  TokenGeneratedFailed: 'TokenGeneratedFailed',
  AccountModal: 'AccountModal',
};

export type DialogId = (typeof DialogId)[keyof typeof DialogId];

export const { useOpenDialogMutation } = mainApi.injectEndpoints({
  endpoints: build => ({
    openDialog: build.mutation<{ open: boolean }, { open: boolean }>({
      queryFn: ({ open }) => {
        return { data: { open } };
      },
    }),
  }),
});
