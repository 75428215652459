import { useCallback } from 'react';

import { DialogId, useOpenDialogMutation } from '../actions/openDialog';

export function useDialog(dialogId: DialogId) {
  const [toggleDialog, { data }] = useOpenDialogMutation({
    fixedCacheKey: dialogId,
  });

  const handleOpen = useCallback(() => {
    void toggleDialog({ open: true });
  }, [toggleDialog]);

  const handleClose = useCallback(() => {
    void toggleDialog({ open: false });
  }, [toggleDialog]);

  return {
    open: !!data?.open,
    handleClose,
    handleOpen,
  };
}
