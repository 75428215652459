import { makeStyles } from 'tss-react/mui';

export const useUnsupportedNetworkStyles = makeStyles()(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  content: {
    maxWidth: 720,
    padding: theme.spacing(13, 15, 10),
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(8, 8, 6),
    },
  },
  title: {
    align: 'center',
    margin: theme.spacing(0, 'auto', 5),
  },
  hint: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    justifyContent: 'center',
    fontSize: 24,
    gap: '0.25em',
    marginBottom: theme.spacing(10),
  },
  hintFirst: {
    marginBottom: theme.spacing(2),
  },
  btn: {
    width: '100%',
  },
}));
