import { makeStyles } from 'tss-react/mui';

export const useGenerateTokenFormStyles = makeStyles()(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(10),
    maxWidth: 500,
    marginTop: theme.spacing(14.5),
  },
  title: {},
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(6),
    [theme.breakpoints.down('sm')]: {
      gap: theme.spacing(4),
    },
  },
  radioGroup: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
    gap: theme.spacing(15),
  },
  label: {
    fontSize: 12,
    fontWeight: 500,
    textTransform: 'uppercase',
  },
}));
