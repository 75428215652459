import { queryFnNotifyWrapper } from '@ankr.com/chain-tools-ui';
import { EWalletId } from '@ankr.com/provider';

import { authApi } from '../../api/authApi';
import { getWriteProvider } from '../../web3Provider';
import { removeStoredWalletId } from '../utils/removeStoredWalletId';

export const {
  useDisconnectMutation,
  endpoints: { disconnect },
} = authApi.injectEndpoints({
  endpoints: build => ({
    disconnect: build.mutation<boolean, EWalletId | undefined>({
      queryFn: queryFnNotifyWrapper<EWalletId | undefined, void, boolean>(
        async (walletId = EWalletId.injected) => {
          const provider = await getWriteProvider(walletId);
          provider.disconnect();

          return { data: true };
        },
      ),
      async onQueryStarted(_args, { dispatch, queryFulfilled }) {
        await queryFulfilled;

        dispatch(authApi.util.resetApiState());
        removeStoredWalletId();
      },
    }),
  }),
});
