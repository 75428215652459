import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Container } from '@mui/material';

import { AddNetworkButton } from '../../../auth/components/AddNetworkButton';
import { ConnectWalletButton } from '../../../auth/components/ConnectWalletButton';
import { LOGO_LINK } from '../../../common/const/values';
import { useHeaderStyles } from './useHeaderStyles';

export function Header() {
  const { classes } = useHeaderStyles();

  return (
    <Container className={classes.container}>
      <Link to={LOGO_LINK}>
        <Box className={classes.logo}>
          <img
            src={`${import.meta.env.VITE_HOMEPAGE}${import.meta.env.VITE_PUBLIC_DIR}/logo.svg`}
            alt={import.meta.env.VITE_TITLE}
            className={classes.logo}
          />
        </Box>
      </Link>

      <Box className={classes.buttons}>
        <AddNetworkButton />
        <ConnectWalletButton size="small" />
      </Box>
    </Container>
  );
}
