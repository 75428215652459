import { Web3Address } from '@ankr.com/chain-tools-types';
import { queryFnNotifyWrapper } from '@ankr.com/chain-tools-ui';
import { EWalletId, IWalletMeta } from '@ankr.com/provider';

import { cacheTags } from '../../api';
import { authApi } from '../../api/authApi';
import { getWriteProvider } from '../../web3Provider';

interface IGetAuthResponse {
  walletId: EWalletId;
  currentAccount: Web3Address;
  isActive: boolean;
  walletMeta: IWalletMeta;
  chainId: number;
}

export const { useGetAuthQuery } = authApi.injectEndpoints({
  endpoints: build => ({
    getAuth: build.query<IGetAuthResponse, EWalletId | undefined>({
      queryFn: queryFnNotifyWrapper<
        EWalletId | undefined,
        never,
        IGetAuthResponse
      >(async (walletId = EWalletId.injected) => {
        const provider = await getWriteProvider(walletId);
        const isActive = provider.isConnected();

        const [currentAccount] = await provider.getWeb3().eth.getAccounts();

        return {
          data: {
            walletId,
            currentAccount,
            isActive,
            walletMeta: provider.getWalletMeta(),
            chainId: Number(await provider.getWeb3().eth.getChainId()),
          },
        };
      }),
      providesTags: [cacheTags.providerConnection],
    }),
  }),
});
