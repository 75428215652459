import { IChain } from '@ankr.com/chain-tools-types';
import Web3, { utils } from 'web3';

export const addExpectedNetwork = (web3: Web3, appConfig: IChain) => {
  return web3.provider?.request({
    method: 'wallet_addEthereumChain',
    params: [
      {
        chainId: utils.numberToHex(appConfig.chainId),
        chainName: appConfig.name,
        rpcUrls: appConfig.rpcUrls,
        blockExplorerUrls: appConfig.blockExplorerUrls.map(item => item.url),
        nativeCurrency: appConfig.nativeCurrency,
      },
    ],
  });
};

export const changeNetwork = async (web3: Web3, appConfig: IChain) => {
  if (!web3.provider?.request) {
    throw new Error('Not switched');
  }

  const tryToSwitch = async () => {
    await web3.provider?.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: utils.numberToHex(appConfig.chainId) }],
    });
  };
  try {
    await tryToSwitch();
  } catch (error) {
    // This error code indicates that the chain has not been switched by a wallet error
    if (
      (error as { code: number }).code === 4902 ||
      (error as { code: number }).code === -32603 || // Metamask Mobile
      (error as { message: string }).message === 'Not supported chainId' // TrustWallet
    ) {
      await addExpectedNetwork(web3, appConfig);
      return;
    }

    throw error;
  }
};
