import React, { JSX } from 'react';
import { Route, Routes } from 'react-router-dom';
import { PageNotFound } from '@ankr.com/chain-tools-ui';
import { t } from '@ankr.com/chain-tools-utils';

import { getGenerateTokenFormRoutes } from './modules/GenerateTokenForm/GenerateTokenFormRoutes';
import { LayoutDefault } from './modules/layout/components/LayoutDefault';

export function AppRoutes(): JSX.Element {
  return (
    <Routes>
      {getGenerateTokenFormRoutes()}
      <Route
        path="*"
        element={
          <LayoutDefault title={t('page-title.page-not-found')}>
            <PageNotFound />
          </LayoutDefault>
        }
      />
    </Routes>
  );
}
