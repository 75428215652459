import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { persistStore } from 'redux-persist';

import { mainApi } from '../modules/api';
import { authApi } from '../modules/api/authApi';
import { i18nReducer } from '../modules/i18n';
import { authEventsMiddleware } from './authEventsMiddleware';

const rootReducer = combineReducers({
  i18n: i18nReducer,
  [mainApi.reducerPath]: mainApi.reducer,
  [authApi.reducerPath]: authApi.reducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      // TODO: fix serializable error
      // https://redux-toolkit.js.org/usage/usage-guide#working-with-non-serializable-data
      serializableCheck: false,
    }).concat([
      mainApi.middleware,
      authApi.middleware,
      authEventsMiddleware.middleware,
    ]),
});

export const persistor = persistStore(store);

setupListeners(store.dispatch);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
