import {
  EventProvider,
  EVENTS,
  isEventProvider,
  ProviderEvents,
} from '@ankr.com/provider';
import { createListenerMiddleware } from '@reduxjs/toolkit';
import { utils } from 'web3';

import { cacheTags } from '../modules/api';
import { authApi } from '../modules/api/authApi';
import { connect } from '../modules/auth/actions/connect';
import { disconnect } from '../modules/auth/actions/disconnect';
import { DISCONNECTION_CACHE_KEY } from '../modules/auth/const';
import { getWriteProvider } from '../modules/web3Provider';

export const authEventsMiddleware = createListenerMiddleware();

authEventsMiddleware.startListening({
  predicate: action => {
    return !!connect.matchFulfilled(action);
  },
  effect: async (action, api) => {
    api.cancelActiveListeners();

    const { dispatch } = api;

    const walletKey = 'injected';
    const provider = await getWriteProvider(walletKey);

    const web3 = provider.getWeb3();

    const eventProvider: EventProvider | null = isEventProvider(
      web3?.currentProvider,
    )
      ? web3?.currentProvider
      : null;

    if (eventProvider === null) return;

    EVENTS.forEach((eventName: ProviderEvents): void => {
      eventProvider.removeAllListeners(eventName);
    });

    eventProvider.on(ProviderEvents.AccountsChanged, data => {
      const account = data?.[0];
      if (!account) {
        const result = dispatch(
          disconnect.initiate(undefined, {
            fixedCacheKey: DISCONNECTION_CACHE_KEY,
          }),
        );
        result.reset();
        return;
      }

      provider.currentAccount = account;

      dispatch(authApi.util.invalidateTags([cacheTags.providerConnection]));
    });

    eventProvider.on(ProviderEvents.ChainChanged, data => {
      try {
        provider.currentChain = Number(utils.hexToNumber(data));
      } finally {
        dispatch(authApi.util.invalidateTags([cacheTags.providerConnection]));
      }
    });

    eventProvider.on(ProviderEvents.Disconnect, data => {
      if (data instanceof Array && data.length === 0) {
        const result = dispatch(
          disconnect.initiate(undefined, {
            fixedCacheKey: DISCONNECTION_CACHE_KEY,
          }),
        );
        result.reset();
      }
    });
  },
});
