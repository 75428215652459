import React, { JSX, useEffect, useMemo, useState } from 'react';
import { useScrollToTop } from '@ankr.com/chain-tools-hooks';
import { Notifications, QueryLoadingAbsolute } from '@ankr.com/chain-tools-ui';
import { EWalletId } from '@ankr.com/provider';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { CssBaseline, ThemeProvider } from '@mui/material';

import '../../../../assets/fonts/fonts.css';

import { useAuthProvider } from '../../../auth/hooks/authProvider';
import { removeStoredWalletId } from '../../../auth/utils/removeStoredWalletId';
import { restoreWalletId } from '../../../auth/utils/restoreWalletId';
import { useLocaleInit } from '../../../i18n/hooks/useLocaleInit';
import { incentivTheme } from '../../../themes/incentivTheme';
import { mainTheme } from '../../../themes/mainTheme';

interface IAppBaseProps {
  children: JSX.Element;
}

export const muiCache = createCache({
  key: 'mui',
});

export function AppBase({ children }: IAppBaseProps): JSX.Element {
  useScrollToTop();

  const [isProviderConnectionAttempted, setProviderConnectionAttempted] =
    useState(false);
  const { connect: connectWallet } = useAuthProvider();

  useEffect(() => {
    async function attemptToConnect() {
      const walletId = restoreWalletId();
      removeStoredWalletId();

      if (walletId) {
        await connectWallet(EWalletId.injected);
      }

      setProviderConnectionAttempted(true);
    }

    void attemptToConnect();
  }, [connectWallet]);

  const { isLocaleReady } = useLocaleInit();

  const theme = useMemo(() => {
    switch (import.meta.env.VITE_PROJECT_NAME) {
      case 'Incentiv':
        return incentivTheme;
      default:
        return mainTheme;
    }
  }, []);

  return (
    <CacheProvider value={muiCache}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {isLocaleReady && isProviderConnectionAttempted ? (
          children
        ) : (
          <QueryLoadingAbsolute />
        )}
        <Notifications />
      </ThemeProvider>
    </CacheProvider>
  );
}
