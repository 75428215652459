import { makeStyles } from 'tss-react/mui';

export const useAccountModalStyles = makeStyles()(theme => ({
  walletWrap: {
    display: 'flex',
    gap: theme.spacing(2),
    alignItems: 'center',
    marginBottom: theme.spacing(6),
  },
  wallet: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
    gap: theme.spacing(2),
    fontSize: 14,
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(2, 3),
    borderRadius: 12,
    minHeight: 48,
    '&:hover': {
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.background.default,
    },
  },
  copyBtn: {},
  disconnectBtn: {
    width: '100%',
  },
}));
