import React, { JSX, useCallback, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { isIntegerPositive } from '@ankr.com/chain-tools-utils';
import { LoadingButton, TextField } from '@ankr.com/ui';
import {
  Box,
  Container,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';

import { DialogId } from '../../../common/actions/openDialog';
import { useDialog } from '../../../common/hooks/useDialog';
import { useTranslation } from '../../../i18n';
import {
  IFormPayload,
  usePostTokenFormMutation,
} from '../../actions/postTokenForm';
import { formTranslation } from '../../translation';
import ArrowRight from './assets/arrow-right.svg?react';
import { GenerateTokenFormFailedModal } from './components/GenerateTokenFormFailedModal';
import { GenerateTokenFormSuccessModal } from './components/GenerateTokenFormSuccessModal';
import { useGenerateTokenFormStyles } from './useGenerateTokenFormStyles';

export function GenerateTokenForm(): JSX.Element {
  const { classes } = useGenerateTokenFormStyles();

  const { keys, t } = useTranslation(formTranslation);

  const { control, handleSubmit, getValues, reset } = useForm<IFormPayload>({
    defaultValues: { name: '', symbol: '', supply: '', pausable: 'yes' },
  });

  const {
    handleOpen: handleSuccessModalOpen,
    handleClose: handleSuccessModalClose,
  } = useDialog(DialogId.TokenGeneratedSuccessfully);
  const {
    handleOpen: handleFailedModalOpen,
    handleClose: handleFailedModalClose,
  } = useDialog(DialogId.TokenGeneratedFailed);

  const [
    postTokenForm,
    {
      data: postTokenData,
      isSuccess: isPostTokenFormSuccess,
      isLoading: isPostTokenFormLoading,
      isError: isPostTokenFormError,
    },
  ] = usePostTokenFormMutation();

  const onSubmit = useCallback(
    (payload: IFormPayload) => {
      void postTokenForm(payload);
    },
    [postTokenForm],
  );

  useEffect(() => {
    if (isPostTokenFormSuccess) {
      handleFailedModalClose();
      handleSuccessModalOpen();
      reset();
    }
  }, [
    handleFailedModalClose,
    handleSuccessModalOpen,
    isPostTokenFormSuccess,
    reset,
  ]);

  useEffect(() => {
    if (isPostTokenFormError) {
      handleSuccessModalClose();
      handleFailedModalOpen();
    }
  }, [handleFailedModalOpen, handleSuccessModalClose, isPostTokenFormError]);

  const handleTryAgain = useCallback(() => {
    handleFailedModalClose();
    onSubmit(getValues());
  }, [getValues, handleFailedModalClose, onSubmit]);

  return (
    <Container>
      <Box className={classes.root}>
        <Typography variant="h1" className={classes.title}>
          {t(keys.generateTokenForm.title)}
        </Typography>
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          noValidate
          autoComplete="off"
          className={classes.form}
        >
          <Controller
            name="name"
            rules={{
              required: t('validation.required'),
            }}
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                variant="outlined"
                helperText={error ? error.message : null}
                error={!!error}
                onChange={onChange}
                value={value}
                fullWidth
                label={t(keys.generateTokenForm.name)}
                placeholder={t(keys.generateTokenForm.namePlaceholder)}
              />
            )}
          />

          <Controller
            name="symbol"
            rules={{
              required: t('validation.required'),
            }}
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                variant="outlined"
                helperText={error ? error.message : null}
                error={!!error}
                onChange={onChange}
                value={value}
                fullWidth
                label={t(keys.generateTokenForm.symbol)}
                placeholder={t(keys.generateTokenForm.symbolPlaceholder)}
              />
            )}
          />

          <Controller
            name="supply"
            rules={{
              required: t('validation.required'),
              validate: {
                isIntegerPositive: v =>
                  isIntegerPositive(Number(v)) ||
                  t('validation.require-positive-integer'),
              },
            }}
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                variant="outlined"
                helperText={error ? error.message : null}
                error={!!error}
                onChange={onChange}
                value={value}
                fullWidth
                type="number"
                inputProps={{
                  step: '1',
                  min: '0',
                  max: Number.MAX_SAFE_INTEGER,
                  inputMode: 'numeric',
                }}
                label={t(keys.generateTokenForm.supply)}
                placeholder={t(keys.generateTokenForm.supplyPlaceholder)}
              />
            )}
          />

          <FormControl>
            <Typography
              variant="subtitle2"
              component={Box}
              className={classes.label}
            >
              {t(keys.generateTokenForm.pausable)}
            </Typography>
            <RadioGroup
              row
              name="pausable-radio-group"
              className={classes.radioGroup}
            >
              <Controller
                name="pausable"
                control={control}
                rules={{
                  required: t('validation.required-one'),
                }}
                render={({ field }) => (
                  <>
                    <FormControlLabel
                      value="yes"
                      control={
                        <Radio
                          {...field}
                          checked={field.value === 'yes'}
                          value="yes"
                          disableFocusRipple
                        />
                      }
                      label={t(keys.generateTokenForm.yes)}
                    />
                    <FormControlLabel
                      value="no"
                      control={
                        <Radio
                          {...field}
                          checked={field.value === 'no'}
                          value="no"
                          disableFocusRipple
                        />
                      }
                      label={t(keys.generateTokenForm.no)}
                    />
                  </>
                )}
              />
            </RadioGroup>
          </FormControl>

          <LoadingButton
            loading={isPostTokenFormLoading}
            size="large"
            type="submit"
            endIcon={<ArrowRight />}
          >
            {t(keys.generateTokenForm.button)}
          </LoadingButton>
        </Box>
      </Box>

      <GenerateTokenFormSuccessModal
        transactionHash={postTokenData?.transactionHash}
        contractAddress={postTokenData?.newContractAddr}
      />
      <GenerateTokenFormFailedModal onClick={handleTryAgain} />
    </Container>
  );
}
