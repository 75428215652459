import { makeStyles } from 'tss-react/mui';

export const useAddNetworkButtonStyles = makeStyles()(theme => ({
  button: {
    fontWeight: 500,
    gap: theme.spacing(2),

    '&:disabled': {
      opacity: 0.4,
    },
  },
  walletIcon: {
    width: 16,
    height: 'auto',
  },
}));
