import React from 'react';
import { Discord, ExternalLink, Telegram, XIcon } from '@ankr.com/ui';
import { Box, IconButton, Typography } from '@mui/material';

import { useTranslation } from '../../../../../i18n';
import { footerTranslation } from '../../translation';
import { useFooterStyles } from '../../useFooterStyles';
import {
  CONTRACT_VERIFIER_LINK,
  DISCORD_LINK,
  TG_LINK,
  TWITTER_LINK,
} from './const';

export function IncentivFooter() {
  const { classes, cx } = useFooterStyles();

  const { keys, t } = useTranslation(footerTranslation);

  return (
    <>
      <Box className={classes.item}>
        <Typography variant="body2" className={classes.copyright}>
          {t(keys.footer.copyright, {
            year: new Date().getFullYear(),
            projectName: import.meta.env.VITE_TITLE,
          })}
        </Typography>
      </Box>

      {!!CONTRACT_VERIFIER_LINK && (
        <Box className={cx(classes.item, classes.contractWrap)}>
          <a
            className={classes.contractBtn}
            href={CONTRACT_VERIFIER_LINK}
            rel="noreferrer"
            target="_blank"
          >
            {t(keys.footer.contractVerifier)}
            <ExternalLink />
          </a>
        </Box>
      )}

      <Box className={cx(classes.item, classes.socialWrap)}>
        {!!DISCORD_LINK && (
          <IconButton
            size="small"
            color="secondary"
            className={classes.socialBtn}
            href={DISCORD_LINK}
            rel="noreferrer"
            target="_blank"
          >
            <Discord sx={{ color: 'inherit' }} />
          </IconButton>
        )}
        {!!TG_LINK && (
          <IconButton
            size="small"
            color="secondary"
            className={classes.socialBtn}
            href={TG_LINK}
            rel="noreferrer"
            target="_blank"
          >
            <Telegram sx={{ color: 'inherit' }} />
          </IconButton>
        )}
        {!!TWITTER_LINK && (
          <IconButton
            size="small"
            color="secondary"
            className={classes.socialBtn}
            href={TWITTER_LINK}
            rel="noreferrer"
            target="_blank"
          >
            <XIcon sx={{ color: 'inherit' }} />
          </IconButton>
        )}
      </Box>
    </>
  );
}
