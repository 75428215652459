import React, { JSX } from 'react';
import { generatePath, Route } from 'react-router-dom';
import { createRouteConfig } from '@ankr.com/utils/createRouteConfig';

import { GuardRoute } from '../auth/components/GuardRoute';
import { LayoutDefault } from '../layout/components/LayoutDefault';
import { GenerateTokenForm } from './screens/GenerateTokenForm';

const PATH = '/';

export const GenerateTokenFormRoutesConfig = createRouteConfig(
  {
    GenerateTokenForm: {
      path: PATH,
      generatePath: () => generatePath(PATH),
    },
  },
  PATH,
);

export function getGenerateTokenFormRoutes(): JSX.Element {
  return (
    <Route
      path={GenerateTokenFormRoutesConfig.root}
      element={
        <LayoutDefault>
          <GuardRoute>
            <GenerateTokenForm />
          </GuardRoute>
        </LayoutDefault>
      }
    />
  );
}
