import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { API_ENDPOINT } from '../common/const/environment';
import { cacheTags } from './cacheTags';

export const mainApi = createApi({
  reducerPath: 'mainApi',
  baseQuery: fetchBaseQuery({
    baseUrl: API_ENDPOINT,
  }),
  tagTypes: Object.values(cacheTags),
  endpoints: () => ({}),
});
